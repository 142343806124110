import FamilyTypeOptionsModal from 'app/components/Hotels/FamilyTypeOptionsModal';
import { ItemType, roomTypes } from 'app/modules/Constants';
import { FC, RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { openInput } from '../../../actions/baseActions';
import { setRoomType, updateRooms } from '../../../actions/hotelActions';
import BasicDropDown from '../Common/BasicDropDown';

interface IRoom {
	adults: number;
	children: number;
}

type RoomsProps = {
	selectedRoomType: ItemType;
	name: string;
	openedInput?: string;
	rooms: IRoom[];
	roomsPortalRef: RefObject<HTMLDivElement>;
	postfix?: string;
	setRoomType: (type: ItemType) => void;
	openInput: (name: string | null) => void;
	updateRooms: (rooms: IRoom[]) => void;
};

enum VALUES {
	ONE_PERSON = 'one-person',
	TWO_PERSON = 'two-person',
	FAMILY = 'family',
}

const Rooms: FC<RoomsProps> = (props) => {
	const { setRoomType, updateRooms, openInput, selectedRoomType, name, roomsPortalRef, postfix } = props;
	const { t } = useTranslation();

	const onSelect = (item: ItemType) => {
		setRoomType(item);

		if (item.value === VALUES.ONE_PERSON) {
			updateRooms([{ adults: 1, children: 0 }]);
		}

		if (item.value === VALUES.TWO_PERSON) {
			updateRooms([{ adults: 2, children: 0 }]);
		}

		if (item.value === VALUES.FAMILY) {
			openInput(`family-type-options${postfix}`);
		} else {
			openInput(null);
		}
	};
	const getCustomSelectedTextForFamily = () => {
		const rooms = props.rooms;
		let guests = 0;
		for (let i = 0; i < rooms.length; i++) {
			guests += rooms[i].adults + rooms[i].children;
		}
		let roomsName = t('keywords.rooms');
		const guestsName = t('keywords.guests');
		if (rooms.length === 1) {
			roomsName = t('keywords.room');
		}
		return `${rooms.length} ${roomsName}, ${guests} ${guestsName}`;
	};

	return (
		<div id="rooms">
			<BasicDropDown
				icon={selectedRoomType.icon}
				mainInput
				showOnSmallDevices
				showCustomSelectedText={selectedRoomType.value === 'family'}
				customSelectedText={getCustomSelectedTextForFamily()}
				onSelect={onSelect}
				name={name}
				selected={selectedRoomType}
				placeholder={t('keywords.rooms')}
				preventCloseInput
				items={roomTypes}
			/>
			<div style={{ position: 'relative' }}>
				<FamilyTypeOptionsModal openedInput={props.openedInput} portalRef={roomsPortalRef} postfix={postfix} />
			</div>
		</div>
	);
};
// @ts-ignore
const mapStateToProps = (state) => ({
	selectedRoomType: state.hotel.selectedRoomType,
	openedInput: state.base.openedInput,
	rooms: state.hotel.rooms,
	random: state.hotel.random,
});
export default connect(mapStateToProps, { setRoomType, updateRooms, openInput })(Rooms);
