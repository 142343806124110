import axios, { AxiosRequestConfig } from 'axios';

export const searchByHotelIdInElastic = async (hid: string | number) => {
	const data = JSON.stringify({
		query: hid,
		precision: 10,
		search_fields: {
			clicktripz_hotel_id: {},
		},
		result_fields: {
			clicktripz_hotel_id: {
				raw: {},
			},
			city: {
				raw: {},
			},
			name: {
				raw: {},
			},
			country: {
				raw: {},
			},
		},
	});

	const config: AxiosRequestConfig = {
		method: 'post',
		url: `${process.env.NEXT_PUBLIC_ELASITC_BASE_URL}/api/as/v1/engines/dev-hotels-en/search`,
		headers: {
			Authorization: `Bearer ${process.env.NEXT_PUBLIC_ELASITC_AUTHORIZATION_TOKEN}`,
			'Content-Type': 'application/json',
		},
		data,
	};

	const res = await axios(config);
	if (res.data.results.length) {
		return {
			type: 'city',
			name: res.data.results[0].name.raw,
			country: res.data.results[0].country.raw,
			city: res.data.results[0].city.raw,
			clicktripzHotelId: res.data.results[0].clicktripz_hotel_id.raw,
		};
	}
	return {};
};
