import React from 'react';
import { connect } from 'react-redux';
import { openInput, setCityByCode, setLocationByIATACode } from '../../../actions/baseActions';
import { selectHotelDestination } from '../../../actions/hotelActions';
import { HOTEL_SET_DESTINATION } from '../../../actions/types';
import { searchByHotelIdInElastic } from '../../api/hotels';
import Helper from '../../modules/Helper';
import AirportSelect from '../Common/AirportSelect';
import { IAirportItem } from '../Common/AirportSelect/Item';

type HotelDestinationProps = {
	name: string;
	autoJump?: boolean;
	preventSetInputBoxClassName?: boolean;
	hotelDestination?: IAirportItem;
	postfix?: string;
	openInput: (name?: string | null) => void;
	setCityByCode: (code: string, type: string) => void;
	selectHotelDestination: (location: IAirportItem | object) => void;
};

class HotelDestination extends React.Component<HotelDestinationProps> {
	componentDidMount() {
		setTimeout(() => {
			const parsed = Helper.parseQueryString(window.location.search, true);
			if (Helper.isEmpty(this.props.selectHotelDestination)) {
				document.getElementById(this.props.name + '-input')?.focus();
				// @ts-ignore
				document.getElementById(this.props.name + '-input')?.select();
			}
			if (parsed.f && parsed.f === 'cz' && parsed.hid) {
				this.searchByHotelIdInElastic(parsed.hid.toString());
			}

			if (!!parsed.destcode && parsed.destcode !== 'undefined' && parsed.destcode !== 'None') {
				if (parsed.destcode.length === 3 && !parsed.destcode.toString().includes(':')) {
					// @ts-ignore
					this.props.setLocationByIATACode(parsed.destcode.toUpperCase(), HOTEL_SET_DESTINATION);
				} else {
					// @ts-ignore
					this.props.setCityByCode(parsed.destcode, HOTEL_SET_DESTINATION);
				}
			}
		}, 1);
	}

	searchByHotelIdInElastic = async (hid: string) => {
		this.props.selectHotelDestination(await searchByHotelIdInElastic(hid));
	};

	componentDidUpdate(prevProps: HotelDestinationProps) {
		const { selectHotelDestination, name } = this.props;

		if (!Helper.isEmpty(selectHotelDestination) && selectHotelDestination !== prevProps.selectHotelDestination) {
			const inputElement = document.getElementById(name + '-input') as HTMLInputElement;
			inputElement.disabled = true;
			inputElement.disabled = false;
		}
	}

	onSelect = (location: IAirportItem | object) => {
		this.props.selectHotelDestination(location);
		if (this.props.autoJump) {
			this.props.openInput(`check-in-date${this.props.postfix}`);
		} else {
			this.props.openInput(null);
		}
	};

	render() {
		return (
			<AirportSelect
				// i18nValidationDropDownKey={'validation.cars.pickUpLocationSelectFromDropDown'}
				preventSetInputBoxClassName={this.props.preventSetInputBoxClassName}
				icon="icon-hotels"
				placeholder={Helper.trans('main.hotels.inputPlaceholder')}
				selected={this.props.hotelDestination}
				name={this.props.name}
				onSelect={this.onSelect}
			/>
		);
	}
}

// @ts-ignore
const mapStateToProps = (state) => ({
	hotelDestination: state.hotel.hotelDestination,
	autoJump: state.base.autoJump,
});

export default connect(mapStateToProps, {
	selectHotelDestination,
	openInput,
	setLocationByIATACode,
	setCityByCode,
})(HotelDestination);
