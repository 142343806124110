import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { openChildInput, openInput } from '../../../actions/baseActions';
import { addRoom, updateRooms } from '../../../actions/hotelActions';
import ButtonWithBlueBorder from '../Common/ButtonWithBlueBorder';
import FadeTransition from '../Common/FadeTransition';
import MobileOptionsList from '../Common/MobileOptionsList';
import SelectBox from '../Common/SelectBox';

const adults = [1, 2, 3, 4, 5, 6];
const children = [0, 1, 2, 3, 4, 5, 6];
type Rooms = {
	adults: number;
	children: number;
}[];
type FamilyTypeOptionsProps = {
	description?: string;
	openedChildInput?: string;
	openedInput?: string;
	rooms: Rooms;
	postfix?: string;
	openChildInput: (name?: string | null) => void;
	openInput: (name?: string | null) => void;
	updateRooms: (rooms: Rooms) => void;
	addRoom: () => void;
};

const FamilyTypeOptions: FC<FamilyTypeOptionsProps> = (props) => {
	const { openedInput, openedChildInput, openChildInput, openInput, updateRooms, addRoom, rooms, postfix } = props;
	const { t } = useTranslation();

	const removeRoom = (key: number) => {
		if (rooms.length === 1) {
			return;
		}
		const updatedRooms = rooms.filter((item, index) => index !== key);
		updateRooms(updatedRooms);
		openChildInput(null);
	};

	const onSelectAdults = (key: number, value: any) => {
		const rooms = [...props.rooms];
		rooms[key] = { ...rooms[key], adults: value };
		updateRooms(rooms);
	};

	const onSelectChildren = (key: number, value: any) => {
		const rooms = [...props.rooms];
		rooms[key] = { ...rooms[key], children: value };
		updateRooms(rooms);
	};

	const onClickContainer = (e: React.MouseEvent<HTMLDivElement>) => {
		const target = e.target as HTMLElement;

		if (!target.classList.contains('selected')) {
			openChildInput();
		}
	};

	const onClickBodyHighlight = () => {
		if (openedChildInput) {
			openChildInput();
			openInput(`family-type-options${postfix}`);
		}
	};

	return (
		<div id={`family-type-options${postfix}`} onClick={onClickContainer}>
			{openedInput === `mobile-select-box${postfix}` ? (
				<div
					style={{
						zIndex: 99999,
					}}
					onClick={onClickBodyHighlight}
					id="body-highlight"
				/>
			) : null}
			{rooms.map((item, key) => {
				return (
					<div key={key} className="room row">
						<div className="col-sm-3 p-0 col-12 ">
							<div className="room-name-area">
								<div className="name">
									{t('keywords.room')} {key + 1}
								</div>
								<div className="remove-icon ">
									<FadeTransition timeout={200} in={rooms.length > 1}>
										<div onClick={() => removeRoom(key)} className="remove-icon-mobile">
											<i className="icon-cancel-circled" />
										</div>
									</FadeTransition>
								</div>
							</div>
							<div style={{ position: 'relative' }}>
								<MobileOptionsList
									name={'adults-select-box-' + key}
									selected={item.adults}
									onSelect={(value) => onSelectAdults(key, value)}
									items={adults}
									postfix={postfix}
								/>
								<MobileOptionsList
									name={'children-select-box-' + key}
									selected={item.children}
									onSelect={(value) => onSelectChildren(key, value)}
									items={children}
									postfix={postfix}
								/>
							</div>
						</div>
						<div className="col-sm-4 p-0 col-12">
							<SelectBox
								name={'adults-select-box-' + key}
								selected={item.adults}
								onSelect={(value) => onSelectAdults(key, value)}
								items={adults}
								label={t('keywords.adults')}
								postfix={postfix}
							/>
						</div>
						<div className="col-sm-4  p-0 col-12">
							<SelectBox
								name={'children-select-box-' + key}
								selected={item.children}
								onSelect={(value) => onSelectChildren(key, value)}
								items={children}
								label={t('keywords.children')}
								postfix={postfix}
							/>
						</div>

						<div className="remove-icon d-none d-sm-block">
							<FadeTransition timeout={200} in={rooms.length > 1}>
								<div onClick={() => removeRoom(key)}>
									<i className="icon-cancel-circled" />
								</div>
							</FadeTransition>
						</div>
					</div>
				);
			})}
			<div id="actions">
				<ButtonWithBlueBorder onClick={addRoom} name={t('keywords.addRoom')} />
				<ButtonWithBlueBorder onClick={() => openInput(null)} name={t('keywords.confirm')} />
			</div>
		</div>
	);
};

// @ts-ignore
const mapStateToProps = (state) => ({
	rooms: state.hotel.rooms,
	openedInput: state.base.openedInput,
	openedChildInput: state.base.openedChildInput,
	random: state.hotel.random,
});
export default connect(mapStateToProps, { addRoom, openInput, updateRooms, openChildInput })(FamilyTypeOptions);
