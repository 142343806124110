import { DEFAULT_LANGUAGE } from 'app/modules/Constants';
import { default as classNames, default as classnames } from 'classnames';
import { Locale, enGB } from 'date-fns/locale';
import dynamic from 'next/dynamic';
import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { doSearchHotels, prePopulateDataFromQueryString } from '../../../actions/hotelActions';
import Helper from '../../modules/Helper';
import { IAirportItem } from '../Common/AirportSelect/Item';
import TooltipError from '../Common/TooltipError';
import Confirmation from '../PriceAlert/Confirmation';
import ModalSignUp from '../PriceAlert/ModalSignUp';
import CheckInDate from './CheckInDate';
import CheckOutDate from './CheckOutDate';
import HotelDestination from './HotelDestination';
import Rooms from './Rooms';

const PriceAlertCheckboxConfirmation = dynamic(() => import('../PriceAlert/PriceAlertCheckboxConfirmation'), {
	ssr: false,
});
type FilterFormProps = {
	priceAlertHandlerChecked: boolean;
	compare?: boolean;
	hotelDestination?: IAirportItem;
	lng: string;
	userCountry: string;
	dateFrom: string;
	dateTo: string;
	postfix?: string;
	prePopulateDataFromQueryString: () => void;
	doSearchHotels: (compare?: boolean) => void;
};

const FilterForm: FC<FilterFormProps> = (props) => {
	const { t } = useTranslation();
	const portalRef = useRef<HTMLDivElement>(null);
	const [locale, setLocale] = useState<Locale>(enGB);
	const [autoredirect, setAutoredirect] = useState(false);
	const isMobile = Helper.isMobile();
	const postfix = props.postfix || '';

	const { compare, lng, priceAlertHandlerChecked, hotelDestination, doSearchHotels, prePopulateDataFromQueryString } =
		props;
	const isRtl = Helper.isRtlLanguage(lng as string);

	useEffect(() => {
		if (lng !== DEFAULT_LANGUAGE) {
			setLocale(Helper.getLocaleByCode(lng));
		}
	}, [lng]);

	useEffect(() => {
		prePopulateDataFromQueryString();
	}, []);

	const getButtonText = () => {
		if (compare) {
			return 'keywords.compare';
		}
		return priceAlertHandlerChecked ? 'keywords.signUpAndSearch' : 'main.hotels.searchBtn';
	};

	useEffect(() => {
		const parsed = Helper.parseQueryString(window.location.search, true);

		const shouldAutoRedirect = parsed.autoredirect === '1' && !autoredirect;
		const hasDestination = parsed.hasOwnProperty('destcode') && !Helper.isEmpty(hotelDestination);

		if (shouldAutoRedirect && hasDestination) {
			setAutoredirect(true);
			doSearchHotels(compare);
		}
	}, [hotelDestination, compare, autoredirect]);

	const handleSearch = () => {
		doSearchHotels(compare);
	};

	return (
		<div id={`filter-form${postfix}`}>
			<br className="d-xl-block d-none" />
			<form id="redirect-back-form" method="post" action={process.env.NEXT_PUBLIC_BACKEND_URL + '/hotels/redirect'} />
			<div className="row search-form">
				<div className="col-xl col-md-12">
					<div className="row main-inputs">
						<div className="col-12 col-md-12 col-xl-4">
							<TooltipError flexOne label={t('main.hotels.goingTo')} name={`hotel-destination${postfix}`}>
								<HotelDestination name={`hotel-destination${postfix}`} postfix={postfix} />
							</TooltipError>
						</div>
						<div className="col-6 col-md-4 col-xl xs-input-full">
							<TooltipError flexOne label={t('main.hotels.checkIn')} name={`check-in-date${postfix}`}>
								<CheckInDate name={`check-in-date${postfix}`} postfix={postfix} lng={lng} />
							</TooltipError>
						</div>
						<div className="col-6 col-md-4 col-xl xs-input-full">
							<TooltipError flexOne label={t('main.hotels.checkOut')} name={`check-out-date${postfix}`}>
								<CheckOutDate name={`check-out-date${postfix}`} postfix={postfix} lng={lng} />
							</TooltipError>
						</div>
						<div className="col-md-4 col-xl-3 position-relative" id="room-type" ref={portalRef}>
							<TooltipError flexOne label={t('keywords.rooms')} name={`room-type${postfix}`}>
								<Rooms name={`room-type${postfix}`} postfix={postfix} roomsPortalRef={portalRef} />
							</TooltipError>
						</div>
					</div>
				</div>
				<Confirmation onlyMobile />

				<div id="search-button-container" className="col-xl-2 col-12 ">
					<button
						id="search-button"
						type="button"
						onClick={handleSearch}
						className={classnames('btn', {
							'price-alert-handler-checked': priceAlertHandlerChecked,
						})}
					>
						<span className="search-button-text">{t(getButtonText())}</span>
					</button>
				</div>

				<div className="hidden md:block">
					<div className={classNames('row', { 'flex-row-reverse': isRtl })}>
						<div className="col-xl col-md-12 pr-1">
							<div
								style={{ width: '100%' }}
								className={classNames('mt-2 d-flex align-items-center', {
									'justify-content-between': !isRtl,
								})}
							>
								<div />
								<div className="d-xl-block d-none">
									<PriceAlertCheckboxConfirmation priceAlertHandlerChecked={priceAlertHandlerChecked} lng={lng} />
								</div>
							</div>
						</div>
						<div className="col-xl-2 col-12 pr-lg-1"></div>
					</div>
					<ModalSignUp />
				</div>
			</div>
		</div>
	);
};

// @ts-ignore
const mapStateToProps = (state) => ({
	priceAlertHandlerChecked: state.base.priceAlertHandlerChecked,
	hotelDestination: state.hotel.hotelDestination,
	lng: state.base.lng,
	dateFrom: state.hotel.checkInDate,
	dateTo: state.hotel.checkOutDate,
	userCountry: state.base.countryCode,
});

export default connect(mapStateToProps, { doSearchHotels, prePopulateDataFromQueryString })(FilterForm);
