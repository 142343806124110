import classnames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { openChildInput, openInput } from '../../../actions/baseActions';
import FadeTransition from './FadeTransition';

type BaseTooltipErrorProps = {
	openInput: (name: string | null) => void;
	openChildInput: (name: string | null) => void;
	onSelect: (item: string | number) => void;
	items: string[] | number[];
	openedChildInput?: string | null;
	openedInput?: string | null;
	name?: string | null;
	selected?: string | null | number;
	postfix?: string;
};
type BaseTooltipErrorState = {};

class MobileOptionsList extends React.Component<BaseTooltipErrorProps, BaseTooltipErrorState> {
	onSelect = (item: string | number) => {
		this.props.onSelect(item);
		this.props.openChildInput(null);
		this.props.openInput(`family-type-options${this.props.postfix}`);
	};

	render() {
		const { items, openedChildInput, name, selected, openedInput, postfix } = this.props;
		const onSelect = this.onSelect;
		return (
			<FadeTransition timeout={500} in={openedChildInput === name && openedInput === `mobile-select-box${postfix}`}>
				<div className="mobile-options">
					{items.map((item, key) => {
						return (
							<div key={key} onClick={() => onSelect(item)} className="option">
								<span
									className={classnames('label', {
										selected: selected === item,
									})}
								/>{' '}
								{item}
							</div>
						);
					})}
				</div>
			</FadeTransition>
		);
	}
}

// @ts-ignore
const mapStateToProps = (state) => ({
	openedChildInput: state.base.openedChildInput,
	openedInput: state.base.openedInput,
});
export default connect(mapStateToProps, { openChildInput, openInput })(MobileOptionsList);
